const env = process.env.REACT_APP_ENV
const envConfig = {
  dev: {
    API_URL: 'http://localhost:8080',
  },
  test: {
    API_URL: 'https://ecosignbe-test.cloud.leroymerlin.it',
  },
  prod: {
    API_URL: 'https://ecosignbe.cloud.leroymerlin.it',
  },
}

export default envConfig[env]
